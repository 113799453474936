import { render, staticRenderFns } from "./default.vue?vue&type=template&id=02adf70e&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeader: require('/var/www/html/fantini/frontend/components/app/header/index.vue').default,AppFooter: require('/var/www/html/fantini/frontend/components/app/footer/index.vue').default,ModalMenu: require('/var/www/html/fantini/frontend/components/modal/menu/index.vue').default,ModalLoading: require('/var/www/html/fantini/frontend/components/modal/loading/index.vue').default,AppCurtain: require('/var/www/html/fantini/frontend/components/app/curtain/index.vue').default})
